import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/noahgilmore.com/noahgilmore.com/src/components/BlogPageLayout.jsx";
import CaptionedImage from '../../components/CaptionedImage';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`At the most recent `}<a parentName="p" {...{
        "href": "https://www.meetup.com/swift-language/"
      }}>{`Swift Language User Group`}</a>{` meetup, `}<a parentName="p" {...{
        "href": "https://twitter.com/patbarry"
      }}>{`Patrick Barry`}</a>{` presented a `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=dA9rGQRwHGs"
      }}>{`great talk`}</a>{` about how Lyft implements dependency injection. I'd highly recommend watching the video - I was impressed by how clean and functional the solution they came up with is. I was going to write up a summary for my coworkers, but figured I might as well put it here for the benefit of anyone who's interested.`}</p>
    <p>{`This post describes the very simple approach to dependency injection presented in the talk, using new functions `}<inlineCode parentName="p">{`bind`}</inlineCode>{` and `}<inlineCode parentName="p">{`mock`}</inlineCode>{`. I'll paraphrase some code in this write up, but the concepts and function names will match the talk.`}</p>
    <p><em parentName="p">{`Edit (4/30/2019): This post describes a nice Swift implementation of a pattern similar to the `}<a parentName="em" {...{
          "href": "https://en.wikipedia.org/wiki/Service_locator_pattern"
        }}>{`Service Locator Pattern`}</a>{`, considered by many to be a strong anti-pattern. Like Singletons, you should use Service Locator carefully - I've added a `}<a parentName="em" {...{
          "href": "#downsides"
        }}>{`section`}</a>{` at the end about drawbacks of this approach.`}</em></p>
    <h2 {...{
      "id": "dependency-injection"
    }}>{`Dependency Injection`}</h2>
    <p>{`I won't go into detail on what dependency injection is, since there's a fair amount of time in the talk dedicated to it. Instead, let's think about an example.`}</p>
    <p>{`Say we're writing an app to display info about cats. We would have a `}<inlineCode parentName="p">{`Cat`}</inlineCode>{` model in our app:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`struct`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Cat`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: Codable {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` name: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`String`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` image: URL`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`And let's assume we have an API to fetch a cat by name:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": ""
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`GET /cat?id=2`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`{`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`    "name": "Maggie",`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`    "image": "https://placekitten.com/200/200"`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`}`}</span></code></pre>
    <p>{`From an architectural point of view, we want to extract this into two different parts:`}</p>
    <ol>
      <li parentName="ol">{`A `}<inlineCode parentName="li">{`NetworkService`}</inlineCode>{` which makes the requests`}</li>
      <li parentName="ol">{`A `}<inlineCode parentName="li">{`CatService`}</inlineCode>{` which exposes a `}<inlineCode parentName="li">{`getCat(id:)`}</inlineCode>{` method (this calls into the network service)`}</li>
    </ol>
    <CaptionedImage filename="swift-di-1.png" alt="Diagram of CatService calling NetworkService" caption="" mdxType="CaptionedImage" />
    <h2 {...{
      "id": "binding"
    }}>{`Binding`}</h2>
    <p>{`There's a clear `}<a parentName="p" {...{
        "href": "#seam"
      }}>{`seam`}</a>{` between the `}<inlineCode parentName="p">{`NetworkService`}</inlineCode>{` and `}<inlineCode parentName="p">{`CatService`}</inlineCode>{` that we can use to test `}<inlineCode parentName="p">{`CatService`}</inlineCode>{`. Let's extract the network's functionality into an interface:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`protocol`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`NetworkInterface`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`makeRequest`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`url`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: URL,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`completion`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`@escaping`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` (Result<Data, `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Error`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`>) -> `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Void`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    )`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`And the concrete implementation:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`private`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`class`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`NetworkService`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: NetworkInterface {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`makeRequest`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`url`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: URL,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`completion`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`@escaping`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` (Result<Data, `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Error`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`>) -> `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Void`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    ) {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`// Use URLSession, etc`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`And now for the really interesting part - `}<inlineCode parentName="p">{`NetworkService`}</inlineCode>{` will `}<em parentName="p">{`expose itself using a special function called bind`}</em>{` (I've added the "SimpleDI" namespace here):`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` getNetwork = SimpleDI.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`bind`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(NetworkInterface.self) { `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`NetworkService`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`() }`}</span></span></code></pre>
    <p><inlineCode parentName="p">{`bind`}</inlineCode>{` returns `}<inlineCode parentName="p">{`getNetwork`}</inlineCode>{` as a function which can be called to get a concrete implementation of the `}<inlineCode parentName="p">{`NetworkInterface`}</inlineCode>{` protocol. `}<inlineCode parentName="p">{`CatService`}</inlineCode>{` can then call `}<inlineCode parentName="p">{`getNetwork`}</inlineCode>{` to get access to the network:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`class`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`CatService`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`getCat`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`id`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Int`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`completion`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`@escaping`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` (Result<Cat, `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Error`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`>) -> `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Void`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`) {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` network = `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`getNetwork`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        network.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`makeRequest`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`/* ... */`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <CaptionedImage filename="swift-di-2.png" alt="Diagram of CatService calling NetworkService, with bind as an intermediary" caption="" mdxType="CaptionedImage" />
    <h2 {...{
      "id": "implementation"
    }}>{`Implementation`}</h2>
    <p>{`Before we talk about why `}<inlineCode parentName="p">{`bind`}</inlineCode>{` is useful, let's discuss the implementation. `}<inlineCode parentName="p">{`bind`}</inlineCode>{` returns a function which takes no parameters and returns a type of `}<inlineCode parentName="p">{`NetworkInterface`}</inlineCode>{`, but it also takes a closure which will be used to generate the concrete implementation.`}</p>
    <p><inlineCode parentName="p">{`bind`}</inlineCode>{`'s body looks like this (again, paraphrased a bit from the talk):`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`private`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`var`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` instantiators: [`}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`String`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Any`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`] = [:]`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`enum`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`SimpleDI`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`static`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`bind`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`T`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`>(`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`_`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`interfaceType`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: T.Type,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`instantiator`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`@escaping`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` () -> T`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    ) -> () -> T {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        instantiators[`}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`String`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`describing`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: interfaceType)] = instantiator`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`return`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`instance`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`private`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`static`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`instance`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`T`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`>() -> T {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` key = `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`String`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`describing`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: T.self)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` instantiator = instantiators[key] as! () -> T`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`return`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`instantiator`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`We take the closure that we're passed and save it in a dictionary (`}<a parentName="p" {...{
        "href": "#dictionary-atomic"
      }}>{`see end note`}</a>{`), then return a function which accesses and calls the closure we provided. Though we have to do some force casting, we're guaranteed that the closure we need will be there when `}<inlineCode parentName="p">{`getNetworkInterface`}</inlineCode>{` is called, since we put it into `}<inlineCode parentName="p">{`instantiators`}</inlineCode>{` before returning from `}<inlineCode parentName="p">{`bind`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "testability"
    }}>{`Testability`}</h2>
    <p>{`Using `}<inlineCode parentName="p">{`bind`}</inlineCode>{` adds a level of indirection at the seam between `}<inlineCode parentName="p">{`NetworkService`}</inlineCode>{` and `}<inlineCode parentName="p">{`CatService`}</inlineCode>{`, which allows us to stub in a mock in tests. In order to do that, we need to define `}<inlineCode parentName="p">{`mock`}</inlineCode>{` as well, and add a bit of more infrastructure:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`private`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`var`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` instantiators: [`}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`String`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Any`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`] = [:]`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`private`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`var`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` mockInstantiators: [`}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`String`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Any`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`] = [:]`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`enum`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`SimpleDI`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`static`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`var`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` isTestEnvironment = `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`false`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`static`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`bind`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`T`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`>(`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`_`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`type`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: T.Type,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`instantiator`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`@escaping`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` () -> T`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    ) -> () -> T {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        instantiators[`}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`String`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`describing`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: type)] = instantiator`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`return`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`instance`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`private`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`static`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`instance`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`T`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`>() -> T {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` key = `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`String`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`describing`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: T.self)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`if`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`isTestEnvironment`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`guard`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` instantiator = mockInstantiators[key] as? () -> T `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`else`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`fatalError`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"Type `}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{`\\\\`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`(key) unmocked in test!"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`return`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`instantiator`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` instantiator = instantiators[key] as! () -> T`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`return`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`instantiator`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`static`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`mock`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`T`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`>(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`_`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`type`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: T.Type, `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`instantiator`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`@escaping`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` () -> T) {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        mockInstantiators[`}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`String`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`describing`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: type)] = instantiator`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`This code isn't very pretty - in fact, checks like `}<inlineCode parentName="p">{`isTestEnvironment`}</inlineCode>{` are generally a code smell that mean you should refactor how the class works to avoid the check. However, this enables us to write tests very easily: all we have to do is set `}<inlineCode parentName="p">{`isTestEnvironment = true`}</inlineCode>{`, and we'll be able to `}<a parentName="p" {...{
        "href": "#mock"
      }}>{`stub in a mock`}</a>{` immediately using `}<inlineCode parentName="p">{`mock`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <p>{`Let's say we want to write a test which makes sure CatService reports an error when the underlying network errors:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`class`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`NetworkThatAlwaysErrors`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: NetworkInterface {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`makeRequest`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`url`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: URL, `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`completion`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`@escaping`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` (Result<Data>) -> `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Void`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`) {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`completion`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`error`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`error`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`NSError`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`domain`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`""`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`code`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`0`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`userInfo`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`nil`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)))`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`class`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`CatServiceTestCase`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: XCTestCase {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`testCatServiceReportsError`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`() {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        SimpleDI.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`isTestEnvironment`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`true`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        SimpleDI.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`mock`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(NetworkInterface.self) { `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`NetworkThatAlwaysErrors`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`() }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` expecation = `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`expectation`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`description`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"Should return error"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` service = `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`CatService`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        service.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`getCatImage`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`named`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"Maggie"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`completion`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: { result `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`in`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`if`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`case`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` .`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`error`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = result {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                expecation.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`fulfill`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`()`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        })`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`waitForExpectations`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`timeout`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`0.2`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p><inlineCode parentName="p">{`bind`}</inlineCode>{` and `}<inlineCode parentName="p">{`mock`}</inlineCode>{` take the dirty work of setting up the DI/mocking infrastructure and hide it under the rug, allowing us to write more expressive tests easily.`}</p>
    <CaptionedImage filename="swift-di-3.png" alt="Diagram of bind and mock being used to separate CatService and NetworkService and stub in a mock in tests" caption="" mdxType="CaptionedImage" />
    <p>{`Some interesting things to note:`}</p>
    <ol>
      <li parentName="ol">{`Many people who've worked with DI have horror stories of passing 10/15+ dependencies into an initializer so they can be mocked in tests. `}<inlineCode parentName="li">{`bind`}</inlineCode>{` brings the argument count down to 0 without sacrificing testability, and makes both the service and the test more elegant.`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`bind`}</inlineCode>{` will error if you forget to mock out an interface in a test, so you're never accidentally calling deeper into dependencies than you mean to.`}</li>
    </ol>
    <a name="downsides"></a>
    <h2 {...{
      "id": "downsides"
    }}>{`Downsides`}</h2>
    <p>{`This approach is very similar to the `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Service_locator_pattern"
      }}>{`Service Locator Pattern`}</a>{`, which is commonly criticized. I really enjoyed `}<a parentName="p" {...{
        "href": "https://badootech.badoo.com/singleton-service-locator-and-tests-in-ios-d69484e88944"
      }}>{`this article`}</a>{` about Service Locator in iOS, and there are a few downsides that I should mention:`}</p>
    <ol>
      <li parentName="ol">{`Using a service locator registry like `}<inlineCode parentName="li">{`bind`}</inlineCode>{` means you have implicit instead of explicit dependencies. Instead of knowing explicitly that `}<inlineCode parentName="li">{`CatService`}</inlineCode>{` depends on `}<inlineCode parentName="li">{`NetworkService`}</inlineCode>{`, you have to look for the call to `}<inlineCode parentName="li">{`getNetwork`}</inlineCode>{`. There's a tradeoff between the simplicity of using a service locator and the fact that it can make services with many dependencies more complex to reason about.`}</li>
      <li parentName="ol">{`With `}<inlineCode parentName="li">{`bind`}</inlineCode>{`, it can be tough to recognize that you can update a test when a dependency of a service is removed. If `}<inlineCode parentName="li">{`CatService`}</inlineCode>{` stopped calling out to the network, it's wouldn't be immediately apparent that `}<inlineCode parentName="li">{`NetworkThatAlwaysErrors`}</inlineCode>{` could be deleted. This can lead to unnessary cognitive overhead in tests.`}</li>
      <li parentName="ol">{`When calling `}<inlineCode parentName="li">{`mock`}</inlineCode>{`, you have to be `}<em parentName="li">{`very`}</em>{` careful to set up your testing framework such that all the mocks get cleared after every test. Otherwise, you might have different behavior based on the order the tests are run, which can lead to flakes.`}</li>
    </ol>
    <h2 {...{
      "id": "conclusion"
    }}>{`Conclusion`}</h2>
    <p>{`It was really interesting to see how Lyft was able to come up with such a simple solution to a complex problem. I'd really recommend watching `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=dA9rGQRwHGs"
      }}>{`the talk`}</a>{` if you're interested in dependency injection and testing. If you'd like to see a working example of `}<inlineCode parentName="p">{`bind`}</inlineCode>{` and `}<inlineCode parentName="p">{`mock`}</inlineCode>{`, I've put one `}<a parentName="p" {...{
        "href": "https://github.com/noahsark769/NGSimpleDIExample"
      }}>{`here`}</a>{` (it has a few differences from the code in this post in order to demonstrate an actual working request and test).`}</p>
    <h2 {...{
      "id": "footnotes"
    }}>{`Footnotes`}</h2>
    <ol>
      <li parentName="ol"><a name="dictionary-atomic"></a>Dictionary is not thread safe, so if you want to use `bind` and `mock` on multiple threads you'll need to implement something similar to an [atomic box](https://www.objc.io/blog/2018/12/18/atomic-variables/) (the talk mentions this).</li>
      <li parentName="ol"><a name="seam"></a>"[Seam](http://wiki.c2.com/?SoftwareSeam)" is a concept that seems to have been first coined in [Working Effectively With Legacy Code](https://www.amazon.com/Working-Effectively-Legacy-Michael-Feathers/dp/0131177052). It's defined as "[a place where two parts of the software meet and where something else can be injected](https://softwareengineering.stackexchange.com/questions/132563/problem-with-understanding-seam-word/311122)".</li>
      <li parentName="ol"><a name="mock"></a>You could even put `mock` in your test target to make sure it can't be called in production!</li>
    </ol>

    <style {...{
      "className": "vscode-highlight-styles"
    }}>{`
  
  .dark-default-dark {
background-color: #1E1E1E;
color: #D4D4D4;
}

.dark-default-dark .mtk1 { color: #D4D4D4; }
.dark-default-dark .mtk2 { color: #1E1E1E; }
.dark-default-dark .mtk3 { color: #6A9955; }
.dark-default-dark .mtk4 { color: #569CD6; }
.dark-default-dark .mtk5 { color: #D16969; }
.dark-default-dark .mtk6 { color: #D7BA7D; }
.dark-default-dark .mtk7 { color: #B5CEA8; }
.dark-default-dark .mtk8 { color: #CE9178; }
.dark-default-dark .mtk9 { color: #646695; }
.dark-default-dark .mtk10 { color: #4EC9B0; }
.dark-default-dark .mtk11 { color: #DCDCAA; }
.dark-default-dark .mtk12 { color: #9CDCFE; }
.dark-default-dark .mtk13 { color: #000080; }
.dark-default-dark .mtk14 { color: #F44747; }
.dark-default-dark .mtk15 { color: #C586C0; }
.dark-default-dark .mtk16 { color: #6796E6; }
.dark-default-dark .mtk17 { color: #808080; }
.dark-default-dark .mtki { font-style: italic; }
.dark-default-dark .mtkb { font-weight: bold; }
.dark-default-dark .mtku { text-decoration: underline; text-underline-position: under; }
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      